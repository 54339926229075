import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase';
import {Route, Switch} from "react-router";

// REDUX & REDUX PERSISTS
import {Provider} from 'react-redux'
import {persistStore} from "redux-persist";
import {PersistGate} from 'redux-persist/integration/react'

// REDUX STORE
import configureStore, {history} from './store';
import {ConnectedRouter} from "connected-react-router";

// STYLES
import './index.css'
import './App.css'

// PAGES
import NoMatchPage from "./pages/NoMatchPage";
import Test from "./pages/Test";

// CONFIGURATION
const store = configureStore();
const persistor = persistStore(store);

const firebaseConfig = {
    apiKey: "AIzaSyBJoWxGmVJnTXdGQqLSflG5IMNPPE3tyt0",
    authDomain: "adn-test-c3ebb.firebaseapp.com",
    databaseURL: "https://adn-test-c3ebb.firebaseio.com",
    projectId: "adn-test-c3ebb",
    storageBucket: "adn-test-c3ebb.appspot.com",
    messagingSenderId: "1083217660485",
    appId: "1:1083217660485:web:e18ecfd1bdc3390096b12c",
    measurementId: "G-D3R8C7G0DB"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
// END CONFIGURATION

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route exact path="/" component={Test}/>
                        <Route component={NoMatchPage}/>
                    </Switch>
                </ConnectedRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
